import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import logo from "../images/logo.gif"
import react from "../images/React.png"

const Navbar = () => {
  const [toggle, setToggle] = useState(false)
  const toggleState = () => {
    setToggle(!toggle)
  }
  return (
    <div class=" ">
      <header class="text-white font-quick">
        <div class="max-w-full mx-auto px-4">
          <div class="flex items-center justify-between">
            <div class="flex space-x-20">
              <Link
                to="/"
                class="flex flex-col title-font font-medium items-center text-white mb-4 md:mb-0 relative"
                activeClassName="flex flex-col title-font font-medium items-center text-white mb-4 md:mb-0 relative"
              >
                <img src={logo} class="w-24 h-24 absolute z-10" alt="logo" />
                <span class="w-44 h-32 absolute bg-[url('../images/Bg-New-3.png')] bg-[#20114A] bg-no-repeat bg-contain"></span>
                <img
                  src={react}
                  class="w-20 h-20 text-white p-2 mb-4 animate-rotate opacity-50"
                  alt="react"
                />
                <span class="text-3xl tracking-wider text-logo font-fredoka z-10">
                  Sageflare
                </span>
                <span class="text-xs text-logo1 font-fredoka">
                  GAMIFICATION COMPANY
                </span>
              </Link>

              <nav class="hidden md:mr-auto md:ml-24 md:py-5 md:pl-4 md:flex flex-wrap items-center tracking-wide text-2xl justify-center">
                <Link
                  to="/"
                  class="mr-10 hover:underline decoration-[#DC3DAA]"
                  activeClassName="mr-10 underline decoration-[#DC3DAA]"
                >
                  Home
                </Link>
                <Link
                  to="/about-us"
                  class="mr-10 hover:underline decoration-[#DC3DAA]"
                  activeClassName="mr-10 underline decoration-[#DC3DAA]"
                >
                  About us
                </Link>
                <Link
                  to="/mission"
                  class="mr-10 hover:underline decoration-[#DC3DAA]"
                  activeClassName="mr-10 underline decoration-[#DC3DAA]"
                >
                  Mission
                </Link>
                <Link
                  to="/team"
                  class="mr-10 hover:underline decoration-[#DC3DAA]"
                  activeClassName="mr-10 underline decoration-[#DC3DAA]"
                >
                  Our Team
                </Link>
              </nav>
            </div>

            <Link
              to="/contact"
              class="hidden md:block relative rounded-full px-5 py-2.5 md:my-5 overflow-hidden group bg-gradient-to-r from-[#B006BB] via-[#D757A4] to-[#FFA98C] hover:bg-gradient-to-r hover:from-[#FFA98C] hover:to-[#B006BB] text-white transition-all ease-out duration-100"
              activeClassName="hidden md:block relative rounded-full px-5 py-2.5 md:my-5 overflow-hidden group bg-gradient-to-r from-[#B006BB] via-[#D757A4] to-[#FFA98C] hover:bg-gradient-to-r hover:from-[#FFA98C] hover:to-[#B006BB] text-white transition-all ease-out duration-100"
            >
              <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
              <span class="relative text-center text-2xl">Contact Us</span>
            </Link>
            <div class="md:hidden flex items-center">
              <button class="outline-none" onClick={toggleState}>
                {toggle ? (
                  <svg
                    class="w-6 h-6 text-gray-500"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                ) : (
                  <svg
                    class="w-6 h-6 text-gray-500"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div>
            {toggle ? (
              <ul class="">
                <li class="active">
                  <Link
                    to="/"
                    class="block text-2xl px-2 py-4 hover:text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                    activeClassName="block text-2xl px-2 py-4 text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    class="block text-2xl px-2 py-4 hover:text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                    activeClassName="block text-2xl px-2 py-4 text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mission"
                    class="block text-2xl px-2 py-4 hover:text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                    activeClassName="block text-2xl px-2 py-4 text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                  >
                    Mission
                  </Link>
                </li>
                <li>
                  <Link
                    to="/team"
                    class="block text-2xl px-2 py-4 hover:text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                    activeClassName="block text-2xl px-2 py-4 text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                  >
                    Our Team
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    class="block text-2xl px-2 py-4 hover:text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                    activeClassName="block text-2xl px-2 py-4 text-[#DC3DAA] text-white text-center transition duration-300 font-semibold"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </header>
    </div>
  )
}

export default Navbar
