import React from "react"
import Navbar from "./Navbar"
import "../styles/global.css"
// import BG from "../images/bg-image.png"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const Layout = ({ children }) => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "bg-image.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              webpOptions: { quality: 100 }
              quality: 100
            )
          }
        }
      }
    `
  )
  const image = getImage(backgroundImage123)
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
      <div class=" w-full min-h-screen bg-cover bg-blend-multiply relative">
        <Navbar />
        {children}
      </div>
    </BackgroundImage>
  )
}

export default Layout
